import { contract } from '@forgd/contract'
import { type ApiFetcherArgs, initClient, tsRestFetchApi } from '@ts-rest/core'
import type { ZodIssue } from 'zod'

interface ApiOptions {
  // optional flag to set the loading state
  loading?: boolean

  // optional flag to skip error toast, defaults to true
  toast?: boolean
}

/**
 * Nuxt-idiomatic alternative to useClient()
 *
 * - mirrors Nuxt's useAsyncData() API
 * - optionally (default: true) shows application toast for 300+ status
 *
 * @see https://github.com/forged-com/forgd/pull/1784
 * @see https://github.com/forged-com/forgd/pull/1813
 */
export function useApi<T = any, Err extends Error = Error>(options?: ApiOptions) {
  const runtimeConfig = useRuntimeConfig()
  const teammateAccessWall = useTeammateAccessWall()
  const baseUrl = process.server ? runtimeConfig.public.apiUrl : '/proxy'

  // cookie
  const cookie = useCookie<string>(runtimeConfig.public.cookie)
  const headers: Record<string, string> = process.server
    ? { cookie: `${runtimeConfig.public.cookie}=${cookie.value}` }
    : {}

  // values
  options = { toast: true, ...options }
  const status = ref<'idle' | 'pending' | 'success'>('idle')
  const loading = ref(options?.loading || false)
  const data = ref<T | undefined>()
  const error = ref<Err | null>(null)

  // calls
  let _args: ApiFetcherArgs
  const api = async (args) => {
    // store for refresh
    _args = args

    // values
    status.value = 'pending'
    loading.value = true
    error.value = null

    // fetch
    const res = await tsRestFetchApi(args)
    loading.value = false

    // success
    if (res.status < 300) {
      data.value = res.body as T
      status.value = 'success'
      error.value = null
      return res
    }

    // other statuses
    status.value = 'idle'
    error.value = res.body as unknown as Error

    // unauthorised
    if (res.status === 403) {
      teammateAccessWall.show()
    }

    // anything else
    else {
      // message
      const { title, description, output } = parseError(res.body as object, res.status)

      // developer
      // eslint-disable-next-line no-console
      console.log(`\n${title}\n\n  > ${args.method} ${args.path}`, output)

      // user
      if (options?.toast) {
        const fn = res.status < 400 ? toast.warning : toast.error
        fn({ title, description })
      }
    }

    // return
    return res
  }

  const refresh = () => {
    return api(_args)
  }

  const clear = () => {
    status.value = 'idle'
    data.value = undefined
    error.value = null
  }

  // objects
  const toast = useAppToast()
  const client = initClient(contract, {
    baseUrl,
    baseHeaders: headers,
    credentials: 'include',
    api,
  })

  // return
  return { client, status, loading, data, error, refresh, clear, toast }
}

function parseError(error: any, status: number) {
  // defaults
  const title = errors[status] || 'Error'
  let description = error?.message
  let output: any = error

  // zod errors
  if (error.paramsResult) {
    const { desc, text } = parseZodError(error.paramsResult)
    description = `Invalid parameters (${desc})`
    output = text
  }
  else if (error.queryResult) {
    const { desc, text } = parseZodError(error.queryResult)
    description = `Invalid query (${desc})`
    output = text
  }

  // return
  return {
    title,
    description,
    output,
  }
}

function parseZodError({ issues }: { issues: ZodIssue[] }) {
  const desc = plural(issues, 'issue')
  const text = issues.map((issue: ZodIssue) => {
    return `  - ${issue.path[0]} : ${issue.message}`
  }).join('\n')
  return { desc, text: `\n\n${text}\n\n` }
}

const errors = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  509: 'Bandwidth Limit Exceeded',
  510: 'Not Extended',
}
